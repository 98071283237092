<script setup lang="ts">
import { computed, onMounted } from "vue";
import { ProductsResult } from "../../services/search.service";
import ProductTeaser from "../../components/productTeaser/productTeaser.vue";
import ProductModuleContentSkeleton from "./productsModuleContentSkeleton.vue";
import { useFavoritesStore } from "../../stores/favoritesStore";

const props = defineProps<{
  heading: string;
  productsResult?: ProductsResult;
  userAuthenticated: boolean;
  favoriteFormHidden: boolean;
  isLoading?: boolean;
  isLoadingFavorites?: boolean;
  showAsColumnOnMobile?: boolean;
  containerClass?: string;
  smallLabel?: boolean;
  hideSizes?: boolean;
  hideColors?: boolean;
  hidePromotion?: boolean;
  productCount?: number;
  useSkeletonLoaders?: boolean;
  hideFavorites?: boolean;
}>();

const favoritesStore = useFavoritesStore();

onMounted(() => {
  if (props.userAuthenticated) {
    favoritesStore.getFavorites();
  }
});
</script>

<template>
  <div
    v-if="!props.isLoading"
    class="product-list product-list__upsell"
    :class="props.containerClass"
  >
    <!-- Heading -->
    <h2 v-if="props.heading && productsResult?.primaryList?.productGroups[0]" class="product-list__heading">
      {{ props.heading }}
    </h2>
    <!-- Result -->
    <ul
      id="category-list"
      class="product-list__items product-list__items--auto-width"
      :class="{
        'product-list__items--column': props.showAsColumnOnMobile,
      }"
      data-layout="4"
    >
      <template
        v-for="productGroup in productsResult?.primaryList?.productGroups"
      >
        <template v-if="productGroup.products && productGroup.products[0]">
          <li :key="`${productGroup.key}-${productGroup.products[0].key}`">
            <ProductTeaser
              :product-group="productGroup"
              :product="productGroup.products[0]"
              :is-user-authenticated="props.userAuthenticated"
              :favorites="favoritesStore.favorites"
              :is-loading-products="props.isLoading"
              :is-loading-favorites="favoritesStore.isLoadingFavorites"
              :use-gtm-tracking="true"
              :user-id="''"
              :small-label="props.smallLabel"
              :hide-sizes="props.hideSizes"
              :hide-colors="props.hideColors"
              :hide-promotion="props.hidePromotion"
              :hide-favorites="props.hideFavorites"
              @toggleFavorite="favoritesStore.toggleFavorite($event)"
            />
          </li>
        </template>
      </template>
    </ul>
  </div>
  <div
    v-if="props.isLoading && props.useSkeletonLoaders"
    :class="props.containerClass"
    class="product-list product-list__upsell"
  >
    <ProductModuleContentSkeleton
      :product-count="props.productCount"
      :show-as-column-on-mobile="props.showAsColumnOnMobile"
    />
  </div>
</template>
  